@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenM";
  src: url("../fonts/Heebo-Medium.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'OpenL';
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

.aboutTitle {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 5rem;
  font-size: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
}

@media (max-width: 1000px) {
  .aboutTitle {
    top: 60px;
  }
}

.homeAbout {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1.5rem 0;
  background-color: #31458A;
  color: white;
}

.homeAbout > section {
  width: 50%;
  margin: 0.5rem 0;
  padding: 0rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1000px) {
  .homeAbout > section {
    width: 100%;
  }
}

.homeAbout > section > .title {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 5rem;
  font-size: 2rem;
}

.homeAbout > section > .textCon {
  width: 80%;
}

.homeAbout > section > a > button {
  width: auto;
  font-size: 0.8rem;
  margin: 1rem 0;
}

@media (max-width: 1000px) {
  .homeAbout > section > a > button {
    font-size: 1.2rem;
    margin: 1rem;
  }
}

.homeAbout > section > svg {
  fill: transparent;
  stroke: white;
  width: 5rem;
  margin-bottom: -1rem;
}

.homeAbout > section:nth-child(1) {
  border-bottom: 0.1px solid white;
}

.homeAbout > section:nth-child(2) > a > button {
  margin-top: -1rem;
}
