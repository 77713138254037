@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenM";
  src: url("../fonts/Heebo-Medium.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'OpenL';
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

.pageCon {
  position: relative;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: start;
      align-self: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0 0 5rem;
  z-index: 1;
  width: 100%;
}

.pageCon > .title {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  background-color: #31458A;
  color: white;
  width: 100%;
  padding: 0;
  font-size: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  z-index: 9;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .pageCon > .title {
    top: 60px;
    font-size: 1.8rem;
  }
}

.pageCon > .headCon {
  position: relative;
  width: 100%;
  height: 25rem;
  margin: 0;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .pageCon > .headCon {
    width: auto;
    height: 15rem;
  }
}

.pageCon > .headCon > img {
  position: relative;
  width: 100%;
  height: auto;
  top: -12rem;
}

@media (max-width: 1000px) {
  .pageCon > .headCon > img {
    position: relative;
    width: 100%;
    height: auto;
    top: 2rem;
    left: 0%;
  }
}

.pageCon > ._about > img {
  top: -30rem;
}

@media (max-width: 1000px) {
  .pageCon > ._about > img {
    top: 0;
  }
}

.pageCon > ._car-insurance > img {
  top: -20rem;
}

@media (max-width: 1000px) {
  .pageCon > ._car-insurance > img {
    top: 2rem;
  }
}

.pageCon > ._home-insurance > img {
  top: -20rem;
}

@media (max-width: 1000px) {
  .pageCon > ._home-insurance > img {
    top: 0rem;
    height: 100%;
  }
}

.pageCon > ._financia-life > img {
  top: -17rem;
}

@media (max-width: 1000px) {
  .pageCon > ._financia-life > img {
    top: 2rem;
  }
}

.pageCon > .textCon {
  overflow: hidden;
  z-index: 1;
  position: relative;
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: right;
  font-size: 1.1rem;
  border-right: 3px solid #31458A;
  padding-right: 2rem;
  height: auto;
}

@media (max-width: 1000px) {
  .pageCon > .textCon {
    width: 95%;
  }
}

.pageCon > .textCon > .textTitle {
  position: relative;
  width: 100%;
  text-align: right;
  font-weight: bolder;
  margin: 0.25rem 0;
  font-size: 2rem;
}

@media (max-width: 1000px) {
  .pageCon > .textCon > .textTitle {
    font-size: 1.7rem;
    line-height: 2rem;
  }
}

.pageCon > .textCon > .text {
  width: 100%;
  direction: RTL;
  white-space: pre-line;
}

.pageCon > .backIcon {
  overflow: hidden;
  position: absolute;
  width: 100%;
  bottom: 0rem;
  left: 0;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.pageCon > .backIcon > svg {
  position: relative;
  width: 25%;
  fill: transparent;
  stroke: #EDF0F6;
  stroke-width: 0.25rem;
}

@media (max-width: 1000px) {
  .pageCon > .backIcon > svg {
    width: 100%;
  }
}

.pageCon > a {
  position: relative;
  width: 20%;
  margin: 2rem 0;
}

@media (max-width: 1000px) {
  .pageCon > a {
    width: 50%;
  }
}

.pageCon > a > button {
  width: 100%;
}

@media (max-width: 1000px) {
  .pageCon > a > button {
    position: relative;
    width: 100%;
    padding: 1rem 0;
  }
}

.pageCon > .bodyCon {
  position: relative;
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

@media (max-width: 1000px) {
  .pageCon > .bodyCon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-line-pack: start;
        align-content: flex-start;
    -ms-flex-item-align: start;
        align-self: flex-start;
    justify-self: flex-start;
    justify-items: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.pageCon > .bodyCon > .map {
  position: relative;
  width: 50%;
}

@media (max-width: 1000px) {
  .pageCon > .bodyCon > .map {
    width: 100%;
  }
}

.pageCon > .bodyCon > .map > iframe {
  width: 80%;
  height: 20rem;
}
