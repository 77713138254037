@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenM";
  src: url("../fonts/Heebo-Medium.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'OpenL';
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 40px;
  background-color: white;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1%, rgba(81, 81, 81, 0.349)), color-stop(5%, #AFAFAF), color-stop(80%, white));
  background-image: linear-gradient(to top, rgba(81, 81, 81, 0.349) 1%, #AFAFAF 5%, white 80%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 999999;
}

@media (max-width: 1000px) {
  nav {
    position: fixed;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(3%, #4461c9), color-stop(50%, #31458A), color-stop(80%, #243264));
    background-image: linear-gradient(to top, #4461c9 3%, #31458A 50%, #243264 80%);
    height: 65px;
  }
}

nav > ul {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90%;
  height: 40px;
  font-size: 0.8rem;
}

@media (max-width: 1000px) {
  nav > ul {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: auto;
    width: 100%;
    right: -100%;
    font-size: 1.5rem;
    z-index: -1;
    background-color: Red;
  }
}

nav > ul > .homeNav {
  width: 100%;
  height: 100%;
  margin: 0;
  list-style: none;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

@media (max-width: 1000px) {
  nav > ul > .homeNav {
    position: relative;
    width: 100%;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(1%, #e7e4e4), to(white));
    background-image: linear-gradient(to top, #e7e4e4 1%, white 100%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    right: 0%;
  }
  nav > ul > .homeNav:nth-child(2) {
    margin-top: 600px;
  }
  nav > ul > .homeNav.open {
    right: 100%;
  }
}

nav > ul > .homeNav > .icon {
  display: none;
}

@media (max-width: 1000px) {
  nav > ul > .homeNav > .icon {
    width: 15%;
    position: relative;
    background-color: #4461c9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 45px;
  }
  nav > ul > .homeNav > .icon > svg {
    position: relative;
    width: 2rem;
    fill: transparent;
    stroke: white;
    stroke-width: 0.3rem;
  }
  nav > ul > .homeNav > .icon > #full {
    fill: white;
  }
}

nav > ul > .homeNav > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

@media (max-width: 1000px) {
  nav > ul > .homeNav > a {
    margin-right: 1.1rem;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}

nav > ul > .homeNav > a > svg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

@media (max-width: 1000px) {
  nav > ul > .homeNav > a > svg {
    display: none;
  }
}

nav > ul > .homeNav > a:hover {
  color: white;
  position: relative;
}

@media (max-width: 1000px) {
  nav > ul > .homeNav > a:hover {
    color: black;
  }
}

nav > ul > .homeNav > a:hover > svg {
  opacity: 0.95;
}

nav > ul > .logo {
  width: 10%;
  height: 100%;
  margin: 0;
  list-style: none;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

@media (max-width: 1000px) {
  nav > ul > .logo {
    position: fixed;
    right: 0;
    height: 80px;
    width: 100%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

nav > ul > .logo > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

nav > ul > .logo > a > svg {
  position: relative;
  fill: #31458A;
  opacity: 1;
  z-index: -1;
  width: 80px;
  background-color: transparent;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

@media (max-width: 1000px) {
  nav > ul > .logo > a > svg {
    width: 150px;
    fill: white;
  }
}

.menu-bth {
  display: none;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .menu-bth {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #31458A;
    position: fixed;
    z-index: 9;
    top: 1.25rem;
    right: 2rem;
    left: 0;
    margin-left: 1rem;
    width: 28px;
    height: 25px;
    padding: 1rem;
  }
  .menu-bth_burger {
    position: absolute;
    left: 0;
    top: 0.5rem;
    width: 28px;
    height: 3px;
    background-color: white;
    color: white;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .menu-bth_burger::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 0;
    width: 28px;
    height: 3px;
    background-color: white;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .menu-bth_burger::after {
    content: ' ';
    position: absolute;
    top: 8px;
    left: 0;
    width: 20px;
    height: 3px;
    background-color: white;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
  }
  .menu-bth_burger.open {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    background-color: transparent;
  }
  .menu-bth_burger.open::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 28px;
    height: 3px;
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
  }
  .menu-bth_burger.open::after {
    top: 0px;
    left: 0;
    width: 28px;
    height: 3px;
    background-color: white;
    -webkit-transform: rotate(-40deg);
            transform: rotate(-40deg);
  }
}
