@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenM";
  src: url("../fonts/Heebo-Medium.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'OpenL';
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

.thanks {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999;
}

.thanks > .bg {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.thanks > .massgaeCon {
  position: relative;
  margin: 15vh auto;
  width: 30%;
  background-color: whitesmoke;
  border-radius: 0rem;
  z-index: 1;
  -webkit-animation: slideInOpacity 1s  ease-in-out;
          animation: slideInOpacity 1s  ease-in-out;
  height: 50vh;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 1000px) {
  .thanks > .massgaeCon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 auto;
    min-width: 100%;
    height: 100vh;
  }
}

.thanks > .massgaeCon > .imgCon {
  display: none;
}

.thanks > .massgaeCon > .imgCon > img {
  position: relative;
  top: 0;
  width: 300%;
  height: 100%;
  right: -100%;
}

@media (max-width: 1000px) {
  .thanks > .massgaeCon > .imgCon > img {
    width: 150%;
    right: -25%;
    height: 120%;
    top: 0;
  }
}

.thanks > .massgaeCon > .closeMsg {
  cursor: pointer;
  position: absolute;
  width: 3rem;
  height: 3rem;
  right: -0.48rem;
  top: -0.5rem;
  border: 0.2rem solid transparent;
  color: black;
  fill: black;
  border-radius: 100rem;
  padding: 0.5rem;
  font-size: 1rem;
  background-color: transparent;
  z-index: 99999;
}

.thanks > .massgaeCon > .textCon {
  width: 100%;
  margin: auto;
  position: relative;
  padding: 1rem;
}

@media (max-width: 1000px) {
  .thanks > .massgaeCon > .textCon {
    width: 80%;
    margin: 0vh 0 1vh;
    height: 90vh;
  }
}

.thanks > .massgaeCon > .textCon > .imgCon {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
}

@media (max-width: 1000px) {
  .thanks > .massgaeCon > .textCon > .imgCon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    right: 0;
    height: auto;
    padding: 0;
    margin: 0 auto;
  }
}

.thanks > .massgaeCon > .textCon > .imgCon > img {
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  right: 0;
}

.thanks > .massgaeCon > .textCon > .star {
  position: relative;
  width: 100%;
  color: black;
  font-size: 0.7rem;
}

.thanks > .massgaeCon > .textCon > .text {
  position: relative;
  width: 100%;
  font-size: 0.9rem;
  font-family: 'OpenR';
  color: black;
}

.thanks > .massgaeCon > .textCon > .text > .title {
  font-size: 2rem;
}

@media (max-width: 1000px) {
  .thanks > .massgaeCon > .textCon > .text {
    margin: 2rem 0;
  }
}

.thanks > .massgaeCon > .textCon > .buttonCon {
  position: relative;
  background: transparent;
}

.thanks > .massgaeCon > .textCon > .buttonCon > button {
  position: relative;
  background-color: black;
  color: white;
  border: 0;
  width: 100%;
  font-size: 1.2rem;
  padding: 0.25rem 0;
  border-radius: 0.5rem;
  font-family: 'OpenL';
  margin: 1rem 0;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .thanks > .massgaeCon > .textCon > .buttonCon > button {
    width: 100%;
    margin: 2rem 0;
  }
}

.thanks > .massgaeCon > .textCon > img {
  position: relative;
  width: 100%;
  height: auto;
  bottom: 0;
}

@media (max-width: 1000px) {
  .thanks > .massgaeCon > .textCon > img {
    position: absolute;
    bottom: 5rem;
    left: 0;
    width: 100%;
    height: auto;
  }
}

.thanks > .massgaeCon > .textCon > a {
  text-decoration: none;
  color: #31458A;
}

@media (max-width: 1000px) {
  .thanks > .massgaeCon > .textCon > a {
    position: relative;
    background-color: #31458A;
    color: white;
    padding: 0.5rem;
  }
}

@media (max-width: 1000px) {
  .thanks > .massgaeCon {
    width: 85%;
  }
}
