@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenM";
  src: url("../fonts/Heebo-Medium.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'OpenL';
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

.sale_con {
  position: relative;
  max-width: 100%;
}

.sale_con > .img_con {
  position: relative;
  width: 100%;
  top: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sale_con > .img_con > img {
  position: relative;
  top: 0;
  width: 100%;
  padding: 0;
  margin: 0 0 -0rem 0;
}

.sale_con > .title {
  position: relative;
  background-color: #31458A;
  color: white;
  width: 100%;
  z-index: 9;
  height: 2rem;
  margin: 0;
}

@media (max-width: 1000px) {
  .sale_con > .title {
    position: relative;
    top: 0px;
    margin: 0;
  }
}

.sale_con > .body_con {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1000px) {
  .sale_con > .body_con {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0rem 0rem;
  }
}

.sale_con > .body_con > .img_con {
  position: relative;
  width: 50%;
  height: 30rem;
  background-color: whitesmoke;
  border-radius: 2rem;
  margin: 1rem 1rem 1rem 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1000px) {
  .sale_con > .body_con > .img_con {
    height: auto;
  }
}

@media (max-width: 1000px) {
  .sale_con > .body_con > .img_con {
    width: 100%;
    border-radius: 0;
    margin: 0rem;
  }
}

.sale_con > .body_con > .img_con > img {
  width: 40%;
}

@media (max-width: 1000px) {
  .sale_con > .body_con > .img_con > img {
    width: 100%;
  }
}

.sale_con .sale {
  width: 50%;
  background-color: whitesmoke;
  border-radius: 2rem;
  margin: 1rem 0.5rem 1rem 1rem;
  height: 30rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
}

@media (max-width: 1000px) {
  .sale_con .sale {
    background-color: white;
    width: 100%;
    border-radius: 0rem;
    margin: 0rem;
  }
}

.sale_con .sale > img {
  display: none;
}

.sale_con .sale > .title {
  margin: 0;
  padding: 0;
  color: #31458A;
}

.sale_con .sale > .text {
  margin: 0;
  padding: 0;
}

.sale_con .sale > form {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 0;
}

.sale_con .sale > form > .inputsCont {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.sale_con .sale > form > .inputsCont > input {
  width: 40%;
  color: #31458A;
  margin: 0.25rem 0;
  background-color: transparent;
  border: 1px solid #31458A;
}

@media (max-width: 1000px) {
  .sale_con .sale > form > .inputsCont > input {
    width: 90%;
    text-align: center;
  }
}

.sale_con .sale > form > .inputsCont > .selectCon {
  border: 1px solid #31458A;
  width: 40%;
  color: #31458A;
}

@media (max-width: 1000px) {
  .sale_con .sale > form > .inputsCont > .selectCon {
    width: 90%;
  }
}

.sale_con .sale > form > .inputsCont > .selectCon > select {
  text-align: center;
}

.sale_con .sale > form > .inputsCont > .selectCon > svg {
  fill: #31458A;
}
