@charset "UTF-8";
@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenM";
  src: url("../fonts/Heebo-Medium.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'OpenL';
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

/* State _ 1*/
.float {
  position: absolute;
  left: 15%;
  top: 120px;
  z-index: 1;
  width: 18rem;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  font-size: 1rem;
  padding: 1rem 0 0;
  margin: 0rem auto;
  background-color: transparent;
  font-family: 'OpenR';
  background-color: white;
  -webkit-box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.39);
          box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.39);
}

.float > img {
  display: none;
}

@media (max-width: 1000px) {
  .float {
    display: none;
  }
}

.float > .form {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 1rem auto;
  direction: RTL;
}

.float > .form > .title {
  position: relative;
  bottom: 0;
  font-size: 1.8rem;
  color: #31458A;
  width: 100%;
  margin: 0rem 0;
}

.float > .form > .text {
  color: #31458A;
  font-size: 0.8rem;
}

.float > .form > .inputsCont {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.float > .form > .inputsCont > .selectCon {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.float > .form > .inputsCont > .selectCon > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 80%;
  height: auto;
  font-family: 'OpenR';
  font-size: 1rem;
  border-radius: 0rem;
  background-color: white;
  color: #31458A;
  border: 0.1rem solid #31458A;
  padding: 0.5rem;
  margin: 0.25rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.float > .form > .inputsCont > .selectCon > select:focus {
  border: 0.1rem solid #31458A;
}

.float > .form > .inputsCont > .selectCon > svg {
  position: absolute;
  width: 1rem;
  left: 3rem;
  top: 1.5rem;
  fill: #31458A;
}

.float > .form > .inputsCont > input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 80%;
  height: auto;
  font-family: 'OpenR';
  font-size: 1rem;
  border-radius: 0rem;
  background-color: white;
  color: #31458A;
  border: 0.1rem solid #31458A;
  padding: 0.5rem;
  margin: 0.25rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (max-width: 1000px) {
  .float > .form > .inputsCont > input {
    width: 100%;
    padding: 1rem;
    margin: 1rem;
    background-color: transparent;
    font-weight: bold;
  }
}

.float > .form > .inputsCont > button {
  width: 80%;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1rem;
  position: relative;
  border: 0;
  background-color: #31458A;
  color: white;
  font-family: 'OpenR';
  font-weight: bolder;
  box-sizing: border-box;
  border-radius: 0rem;
  padding: 0.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1000px) {
  .float > .form > .inputsCont > button {
    min-width: 90%;
    text-align: center;
    margin: 0.5rem 1rem;
    padding: 0.7rem;
    font-size: 1.3rem;
  }
}

.float > .form > .star {
  width: 100%;
  font-family: 'OpenL';
  font-size: 0.8rem;
  margin: 0.5rem 0;
}

.float > .form > .pirsumdiv {
  width: 100%;
  text-align: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.4rem 0;
  z-index: 1;
}

.float > .form > .pirsumdiv > label {
  position: relative;
  width: auto;
  font-size: 1rem;
  font-family: 'OpenL';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: justify;
  color: #31458A;
  z-index: 1;
}

.float > .form > .pirsumdiv > input[type="checkbox"] {
  position: relative;
  width: 2rem;
  display: none;
}

.float > .form > .pirsumdiv > label:before {
  position: relative;
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  background-color: transparent;
  border: 0.1rem solid #31458A;
  margin-left: 1rem;
  top: -0.1rem;
}

.float > .form > .pirsumdiv > input[type="checkbox"]:checked + label:after {
  position: absolute;
  content: '✓';
  width: 1rem;
  height: 1rem;
  top: 0.1rem;
  background-color: transparent;
  font-size: 0.7rem;
  font-weight: bolder;
  color: #31458A;
  right: 0.2rem;
}

.float > .form > svg {
  width: 60%;
}

.float > .endReg {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 100%;
}

.float > .endReg > .bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: opacity 0.5s  ease-in;
          animation: opacity 0.5s  ease-in;
}

@-webkit-keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideInOpacity {
  from {
    top: -10rem;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes slideInOpacity {
  from {
    top: -10rem;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

#op {
  font-family: 'OpenB';
}

/*State _ 2*/
.static {
  position: relative;
  left: 0%;
  top: 0;
  z-index: 1;
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1rem;
  padding: 1rem 0 0;
  margin: 0rem auto;
  background-color: transparent;
  font-family: 'OpenR';
  background-color: white;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .static {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}

.static > img {
  position: relative;
  width: 100%;
  height: auto;
}

@media (max-width: 1000px) {
  .static > img {
    width: 500%;
  }
}

@media (max-width: 1000px) {
  .static {
    position: relative;
    left: 0%;
    top: 0;
    width: 100%;
    height: auto;
  }
}

.static > .form {
  position: absolute;
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 1rem auto;
  direction: RTL;
}

@media (max-width: 1000px) {
  .static > .form {
    margin: 0rem auto;
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.static > .form > .title {
  position: relative;
  bottom: 0;
  font-size: 1.8rem;
  color: white;
  width: 100%;
  margin: 0.1rem 0;
}

@media (max-width: 1000px) {
  .static > .form > .title {
    font-size: 2rem;
    color: white;
    line-height: 2.6rem;
    font-family: 'OpenM';
    padding: 5rem 0 0;
  }
}

.static > .form > .text {
  margin: 0 0 1rem;
  color: white;
  font-size: 0.8rem;
}

@media (max-width: 1000px) {
  .static > .form > .text {
    font-size: 1rem;
    padding: 0 0 0rem;
  }
}

.static > .form > .inputsCont {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

@media (max-width: 1000px) {
  .static > .form > .inputsCont {
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.static > .form > .inputsCont > input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 20%;
  height: auto;
  font-family: 'OpenR';
  font-size: 1rem;
  border-radius: 0rem;
  background-color: transparent;
  color: white;
  border: 0.1rem solid white;
  padding: 0.5rem;
  margin: 0 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (max-width: 1000px) {
  .static > .form > .inputsCont > input {
    width: 100%;
    padding: 1rem;
    margin: 0.5rem;
    background-color: transparent;
    font-weight: bold;
  }
}

.static > .form > .inputsCont > .selectCon {
  position: relative;
  width: 20%;
  margin: 0 0.5rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 0.1rem solid white;
  margin: 0.5rem;
  box-sizing: border-box;
}

@media (max-width: 1000px) {
  .static > .form > .inputsCont > .selectCon {
    height: 3.5rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0.5rem 0 1rem;
  }
}

.static > .form > .inputsCont > .selectCon > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: 0;
  width: 100%;
  height: auto;
  font-family: 'OpenR';
  font-size: 1rem;
  border-radius: 0rem;
  color: white;
  height: 100%;
  background-color: transparent;
  padding: 0.5rem;
}

@media (max-width: 1000px) {
  .static > .form > .inputsCont > .selectCon > select {
    width: 100%;
    padding: 0 1rem;
    margin: 0.5rem;
    background-color: transparent;
    font-weight: bold;
  }
}

.static > .form > .inputsCont > .selectCon > select > option {
  color: #31458A;
}

.static > .form > .inputsCont > .selectCon > svg {
  position: absolute;
  width: 1rem;
  left: 0.5rem;
  top: 0.9rem;
  fill: white;
}

@media (max-width: 1000px) {
  .static > .form > .inputsCont > .selectCon > svg {
    width: 1rem;
    top: 1.5rem;
    left: 1rem;
  }
}

.static > .form > .inputsCont > button {
  width: 20%;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1rem;
  position: relative;
  border: 0;
  background-color: white;
  color: white;
  font-family: 'OpenR';
  font-weight: bolder;
  box-sizing: border-box;
  border-radius: 0rem;
  padding: 0.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #31458A;
  padding: 0.5rem;
  border: 0.1rem solid white;
}

@media (max-width: 1000px) {
  .static > .form > .inputsCont > button {
    min-width: 90%;
    text-align: center;
    margin: 0.5rem 1rem;
    padding: 0.7rem;
    font-size: 1.3rem;
  }
}

@media (max-width: 1000px) {
  .static > .form > .inputsCont > button {
    width: 100%;
    margin: 0rem;
  }
}

.static > .form > .star {
  width: 100%;
  font-family: 'OpenL';
  font-size: 0.8rem;
  margin: 0.5rem 0;
}

.static > .form > .pirsumdiv {
  width: 100%;
  text-align: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.4rem 0;
}

.static > .form > .pirsumdiv > label {
  position: relative;
  width: auto;
  font-size: 1rem;
  font-family: 'OpenL';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: justify;
  color: white;
}

@media (max-width: 1000px) {
  .static > .form > .pirsumdiv > label {
    font-size: 1rem;
    word-spacing: -0.19rem;
    word-spacing: 0.1rem;
  }
}

.static > .form > .pirsumdiv > input[type="checkbox"] {
  position: relative;
  width: 2rem;
  display: none;
}

.static > .form > .pirsumdiv > label:before {
  position: relative;
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  background-color: transparent;
  border: 0.1rem solid white;
  margin-left: 1rem;
  top: -0.1rem;
}

.static > .form > .pirsumdiv > input[type="checkbox"]:checked + label:after {
  position: absolute;
  content: '✓';
  width: 1rem;
  height: 1rem;
  top: 0.1rem;
  background-color: transparent;
  font-size: 0.7rem;
  font-weight: bolder;
  color: white;
  right: 0.2rem;
}

@media (max-width: 1000px) {
  .static > .form > .pirsumdiv > input[type="checkbox"]:checked + label:after {
    top: 0.25rem;
  }
}

.static > .form > svg {
  width: 60%;
  display: none;
}

@media (max-width: 1000px) {
  .static > .form > svg {
    width: 60%;
    padding: 2rem;
  }
}

.static > .endReg {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 100%;
}

.static > .endReg > .bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: opacity 0.5s  ease-in;
          animation: opacity 0.5s  ease-in;
}

/*State _ 3*/
.page {
  position: relative;
  left: 0%;
  top: 0;
  z-index: 1;
  width: 50%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1rem;
  padding: 1rem 0 0;
  margin: 0rem auto;
  background-color: transparent;
  font-family: 'OpenR';
  background-color: white;
  overflow: hidden;
}

@media (max-width: 1000px) {
  .page {
    justify-self: flex-start;
    margin: 0 0 5rem;
    padding: 0;
  }
}

@media (max-width: 1000px) {
  .page {
    position: relative;
    left: 0%;
    top: 0;
    width: 100%;
    height: auto;
  }
}

.page > .form {
  position: relative;
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 1rem auto;
  direction: RTL;
}

@media (max-width: 1000px) {
  .page > .form {
    margin: 0rem auto;
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.page > .form > .title {
  position: relative;
  bottom: 0;
  font-size: 1.8rem;
  color: #31458A;
  width: 100%;
  margin: 1.5rem 0;
}

@media (max-width: 1000px) {
  .page > .form > .title {
    font-size: 2rem;
    line-height: 2.6rem;
    font-family: 'OpenM';
    padding: 0rem 0 0;
  }
}

.page > .form > .text {
  margin: 0 0 1rem;
  color: white;
  font-size: 0.8rem;
}

@media (max-width: 1000px) {
  .page > .form > .text {
    font-size: 1rem;
    padding: 0 0 0rem;
  }
}

.page > .form > .inputsCont {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (max-width: 1000px) {
  .page > .form > .inputsCont {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.page > .form > .inputsCont > input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 20%;
  height: auto;
  font-family: 'OpenR';
  font-size: 1rem;
  border-radius: 0rem;
  background-color: whitesmoke;
  color: #31458A;
  border: 0.1rem solid transparent;
  padding: 0.5rem;
  margin: 0 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 33%;
  margin: 0;
}

@media (max-width: 1000px) {
  .page > .form > .inputsCont > input {
    width: 100%;
    padding: 1rem;
    margin: 0.5rem;
    font-weight: bold;
  }
}

.page > .form > .formFooter {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.page > .form > .formFooter > textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 20%;
  height: auto;
  font-family: 'OpenR';
  font-size: 1rem;
  border-radius: 0rem;
  background-color: whitesmoke;
  color: #31458A;
  border: 0.1rem solid transparent;
  padding: 0.5rem;
  margin: 0 0.25rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 5rem;
  margin: 0.35rem 0;
}

@media (max-width: 1000px) {
  .page > .form > .formFooter > textarea {
    width: 100%;
    padding: 1rem;
    margin: 0.5rem;
    font-weight: bold;
  }
}

.page > .form > .formFooter > button {
  width: 100%;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1rem;
  position: relative;
  border: 0;
  background-color: #31458A;
  color: white;
  font-family: 'OpenR';
  font-weight: bolder;
  box-sizing: border-box;
  border-radius: 0rem;
  padding: 0.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  padding: 0.5rem;
  border: 0.1rem solid #31458A;
}

@media (max-width: 1000px) {
  .page > .form > .formFooter > button {
    min-width: 90%;
    text-align: center;
    margin: 0.5rem 1rem;
    padding: 0.7rem;
    font-size: 1.3rem;
  }
}

@media (max-width: 1000px) {
  .page > .form > .formFooter > button {
    width: 100%;
    margin: 0rem;
  }
}

.page > .form > .star {
  width: 100%;
  font-family: 'OpenL';
  font-size: 0.8rem;
  margin: 0.5rem 0;
}

.page > .form > .pirsumdiv {
  width: 100%;
  text-align: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.4rem 0;
}

.page > .form > .pirsumdiv > label {
  position: relative;
  width: auto;
  font-size: 1rem;
  font-family: 'OpenL';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: justify;
  color: #31458A;
}

@media (max-width: 1000px) {
  .page > .form > .pirsumdiv > label {
    font-size: 1rem;
    word-spacing: -0.19rem;
    word-spacing: 0.1rem;
  }
}

.page > .form > .pirsumdiv > input[type="checkbox"] {
  position: relative;
  width: 2rem;
  display: none;
}

.page > .form > .pirsumdiv > label:before {
  position: relative;
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  background-color: transparent;
  border: 0.1rem solid #31458A;
  margin-left: 1rem;
  top: -0.1rem;
}

.page > .form > .pirsumdiv > input[type="checkbox"]:checked + label:after {
  position: absolute;
  content: '✓';
  width: 1rem;
  height: 1rem;
  top: 0.1rem;
  background-color: transparent;
  font-size: 0.7rem;
  font-weight: bolder;
  color: #31458A;
  right: 0.2rem;
}

@media (max-width: 1000px) {
  .page > .form > .pirsumdiv > input[type="checkbox"]:checked + label:after {
    top: 0.25rem;
  }
}

.page > .form > svg {
  width: 60%;
  display: none;
}

@media (max-width: 1000px) {
  .page > .form > svg {
    width: 60%;
    padding: 2rem;
  }
}

.page > .endReg {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 100%;
}

.page > .endReg > .bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: opacity 0.5s  ease-in;
          animation: opacity 0.5s  ease-in;
}

/*State _ 4*/
.sale > .form > .title {
  position: relative;
  bottom: 0;
  font-size: 1.8rem;
  color: #31458A;
  width: 100%;
  margin: 1.5rem 0 0.5rem;
}

@media (max-width: 1000px) {
  .sale > .form > .title {
    font-size: 2rem;
    line-height: 2.6rem;
    font-family: 'OpenM';
    padding: 0rem 0 0;
  }
}

.sale > .form > .text {
  margin: 0 0 0.5rem;
  color: #31458A;
  font-size: 0.8rem;
}

@media (max-width: 1000px) {
  .sale > .form > .text {
    font-size: 1rem;
    padding: 0 0 0rem;
  }
}

.sale > .form > .inputsCont {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sale > .form > .inputsCont > .selectCon {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sale > .form > .inputsCont > .selectCon > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 100%;
  height: 100%;
  font-family: 'OpenR';
  font-size: 1rem;
  border-radius: 0rem;
  background-color: transparent;
  color: #31458A;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
}

.sale > .form > .inputsCont > .selectCon > select:focus {
  border: 0.1rem solid #31458A;
}

.sale > .form > .inputsCont > .selectCon > svg {
  position: absolute;
  width: 1rem;
  left: 0.5rem;
  top: 1.1rem;
  fill: #31458A;
}

@media (max-width: 1000px) {
  .sale > .form > .inputsCont > .selectCon > svg {
    top: 1.5rem;
  }
}

.sale > .form > .inputsCont > input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  width: 80%;
  height: auto;
  font-family: 'OpenR';
  font-size: 1rem;
  border-radius: 0rem;
  background-color: white;
  color: #31458A;
  border: 0.1rem solid #31458A;
  padding: 0.5rem;
  margin: 0.25rem 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (max-width: 1000px) {
  .sale > .form > .inputsCont > input {
    width: 100%;
    padding: 1rem;
    margin: 1rem;
    background-color: transparent;
    font-weight: bold;
  }
}

.sale > .form > .inputsCont > button {
  width: 40%;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1rem;
  position: relative;
  border: 0;
  background-color: #31458A;
  color: white;
  font-family: 'OpenR';
  font-weight: bolder;
  box-sizing: border-box;
  border-radius: 0rem;
  padding: 0.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1000px) {
  .sale > .form > .inputsCont > button {
    min-width: 90%;
    text-align: center;
    margin: 0.5rem 1rem;
    padding: 0.7rem;
    font-size: 1.3rem;
  }
}

.sale > .form > .pirsumdiv {
  width: 100%;
  text-align: justify;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0.4rem 0;
}

.sale > .form > .pirsumdiv > label {
  position: relative;
  width: auto;
  font-size: 1rem;
  font-family: 'OpenL';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: justify;
  color: #31458A;
}

@media (max-width: 1000px) {
  .sale > .form > .pirsumdiv > label {
    font-size: 1rem;
    word-spacing: -0.19rem;
    word-spacing: 0.1rem;
  }
}

.sale > .form > .pirsumdiv > input[type="checkbox"] {
  position: relative;
  width: 2rem;
  display: none;
}

.sale > .form > .pirsumdiv > label:before {
  position: relative;
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  background-color: transparent;
  border: 0.1rem solid #31458A;
  margin-left: 1rem;
  top: -0.1rem;
}

.sale > .form > .pirsumdiv > input[type="checkbox"]:checked + label:after {
  position: absolute;
  content: '✓';
  width: 1rem;
  height: 1rem;
  top: 0.1rem;
  background-color: transparent;
  font-size: 0.7rem;
  font-weight: bolder;
  color: #31458A;
  right: 0.2rem;
}

@media (max-width: 1000px) {
  .sale > .form > .pirsumdiv > input[type="checkbox"]:checked + label:after {
    top: 0.25rem;
  }
}

.sale > .form > svg {
  width: 60%;
  display: none;
}

@media (max-width: 1000px) {
  .sale > .form > svg {
    width: 60%;
    padding: 2rem;
  }
}

.sale > .endReg {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  width: 100%;
}

.sale > .endReg > .bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-animation: opacity 0.5s  ease-in;
          animation: opacity 0.5s  ease-in;
}

.scrollCon {
  position: fixed;
  opacity: 0;
  z-index: -1;
}

@media (max-width: 1000px) {
  .scrollCon {
    width: 100%;
    background-color: white;
    height: 5rem;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    border-top: 1px solid rgba(0, 0, 0, 0.164);
    opacity: 1;
  }
}

/* ========================Alert Style================================= */
/* ========plach holders======== */
.static > form > .inputsCont > input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white;
  opacity: 1;
}

.static > form > .inputsCont > input::-moz-placeholder {
  /* Firefox 19+ */
  color: white;
  opacity: 1;
}

.static > form > .inputsCont > input:-ms-input-placeholder {
  /* IE 10+ */
  color: white;
  opacity: 1;
}

.static > form > .inputsCont > input:-moz-placeholder {
  /* Firefox 18- */
  color: white;
  opacity: 1;
}

/* ========plach holders======== */
.float > form > .inputsCont > input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #31458A;
  opacity: 1;
}

.float > form > .inputsCont > input::-moz-placeholder {
  /* Firefox 19+ */
  color: #31458A;
  opacity: 1;
}

.float > form > .inputsCont > input:-ms-input-placeholder {
  /* IE 10+ */
  color: #31458A;
  opacity: 1;
}

.float > form > .inputsCont > input:-moz-placeholder {
  /* Firefox 18- */
  color: #31458A;
  opacity: 1;
}

/* ========plach holders======== */
.sale > form > .inputsCont > input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #31458A;
  opacity: 1;
}

.sale > form > .inputsCont > input::-moz-placeholder {
  /* Firefox 19+ */
  color: #31458A;
  opacity: 1;
}

.sale > form > .inputsCont > input:-ms-input-placeholder {
  /* IE 10+ */
  color: #31458A;
  opacity: 1;
}

.sale > form > .inputsCont > input:-moz-placeholder {
  /* Firefox 18- */
  color: #31458A;
  opacity: 1;
}
