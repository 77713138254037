@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenM";
  src: url("../fonts/Heebo-Medium.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'OpenL';
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

.header_img {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0;
}

.header_img > img {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 0 -0.5rem 0;
  padding: 0;
}

.header_sec {
  height: auto;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.header_sec > img {
  position: relative;
  width: 100%;
}

@media (max-width: 1000px) {
  .header_sec > img {
    width: 140%;
    left: 20%;
  }
}

.header_sec > .con {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1000px) {
  .header_sec > .con {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.header_sec > .con > .text_con {
  position: relative;
  width: auto;
  margin-right: -25%;
  top: -0.5rem;
  white-space: pre-line;
  color: white;
}

@media (max-width: 1000px) {
  .header_sec > .con > .text_con {
    white-space: normal;
    color: #31458A;
    top: 2.5rem;
    margin: 0 auto;
  }
}

.header_sec > .con > .text_con > .text {
  text-align: right;
  font-family: 'OpenR';
  font-size: 2rem;
  line-height: 2rem;
}

@media (max-width: 1000px) {
  .header_sec > .con > .text_con > .text {
    font-size: 1.5rem;
  }
}

.header_sec > .con > .text_con > a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.header_sec > .con > .text_con > a > button {
  margin: 10px 0;
  width: 90%;
}
