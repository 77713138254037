@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenM";
  src: url("../fonts/Heebo-Medium.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'OpenL';
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

.clients_con {
  position: relative;
  width: 100%;
}

@media (max-width: 1000px) {
  .clients_con {
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding: 1rem 0;
  }
}

.clients_con > .clinetTitle {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2rem;
  padding: 1rem;
}

.clients_con > img {
  position: relative;
  width: 100%;
}
