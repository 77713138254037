@font-face {
  font-family: "OpenR";
  src: url("../fonts/Heebo-Regular.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenL";
  src: url("../fonts/Heebo-Light.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenM";
  src: url("../fonts/Heebo-Medium.ttf") format("opentype");
  /* here you go, IE */
  font-weight: normal;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'OpenL';
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* Safari */
@-webkit-keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

@keyframes leftSlide {
  0% {
    opacity: 0;
    left: 100%;
  }
  100% {
    opacity: 1;
    left: 0%;
  }
}

/* Safari */
@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
}

body, .App {
  position: relative;
  height: 100vh;
  direction: RTL;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background-color: white;
}

body > main, .App > main {
  position: relative;
  z-index: 0;
  font-family: 'OpenL';
  margin: 0;
  width: 100%;
}

body a, .App a {
  text-decoration: none;
  color: black;
}

.fa-trash-alt {
  cursor: pointer;
}

p {
  background-color: white;
  height: 500rem;
}

button {
  position: relative;
  background-color: white;
  border: 0;
  color: #31458A;
  padding: 10px 15px;
  border-radius: 30px;
  font-family: 'OpenR';
  font-size: 1.2rem;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid white;
}

button:hover {
  background-color: #31458A;
  color: white;
}

@media (max-width: 1000px) {
  button {
    padding: 2.5px 5px;
  }
}

.mob {
  display: none;
}

@media (max-width: 1000px) {
  .mob {
    display: inline;
  }
}

.desk {
  display: inline;
}

@media (max-width: 1000px) {
  .desk {
    display: none;
  }
}
